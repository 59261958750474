import React, { Component } from 'react'
import { Segment, Grid } from 'semantic-ui-react'
import { Paragraph1 } from '@styles/Global.styles'
import { FilterableHtmlContentBlockFilter, FilterableHtmlContentBlockTitle, FilterableHtmlContentBlockFilterContainer } from './FilterableHtmlContentBlock.styles'


interface FilterableHtmlContentBlockState {
    activeIndex: number
}
interface FilterableHtmlContentBlockProps {
    activeIndex: number,
    ContentItems: {
        Title: string
        Content: string
    }[]
}

class FilterableHtmlContentBlock extends Component<FilterableHtmlContentBlockProps,FilterableHtmlContentBlockState> {
    constructor(props:FilterableHtmlContentBlockProps){
        super(props);
        this.state = {
            activeIndex: this.props.activeIndex ? this.props.activeIndex : 0
        }
    }

    handleClick = (index: number) => {
        this.setState({ activeIndex: index })
    }

    render = () => {
        const { activeIndex } = this.state
        const{ ContentItems } = this.props;
        return <Segment basic>
                    <Grid centered>
                        <Grid.Row>
                            <FilterableHtmlContentBlockFilterContainer mobile={16} tablet={8} computer={7} widescreen={5} largeScreen={5}>
                                {
                                    ContentItems.map((contentItem, index) => {
                                        return (
                                            <FilterableHtmlContentBlockFilter className={index === activeIndex ? 'active': ''} onClick={() => this.handleClick(index)} key={index}>
                                                {contentItem.Title}
                                            </FilterableHtmlContentBlockFilter>
                                        )
                                    })
                                }
                            </FilterableHtmlContentBlockFilterContainer>
                            <Grid.Column mobile={16} tablet={8} computer={9} widescreen={11} largeScreen={11}>
                                <div>
                                    <FilterableHtmlContentBlockTitle>
                                        {ContentItems[activeIndex].Title}
                                    </FilterableHtmlContentBlockTitle>
                                    <div dangerouslySetInnerHTML={{ __html: ContentItems[activeIndex].Content }}></div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
    }
}


export default FilterableHtmlContentBlock