import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {SEO, mapToSEO} from '@components/SEO'
import { mapToPageHeader, Banner } from "@components/Banner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { FilterableHtmlContentBlock } from "@components/FilterableHtmlContentBlock"

interface Props{
  data: any;
}
const queryString = require("query-string")

class TermsAndConditionsLandingPage extends React.Component<Props>{

  loadFromQuerystring = (location: any): any => {
    if (location.search) {
      const query = queryString.parse(location.search, {
        parseBooleans: true,
        parseNumbers: true,
      })
      
      const reserved = query.reserved != undefined ? query.reserved : 0
      return {
        reserved: reserved
      }
    } else{
      return {reserved: 0}
    }
  }

  render(){
    const {elements} = this.props.data.kontentItemTermsAndConditions;
    const metadata = mapToPageHeader(elements);
    const seoData = mapToSEO(elements);
    const termsAndConditionsItems = elements.terms_and_conditions.linked_items.map((terms: any) => {
        return {
            Title: terms.elements?.title.value || '',
            Content: terms.elements?.content.value || ''
        }
    });
    let activeIndex = 0

    if(typeof window !== "undefined"){
      const query = this.loadFromQuerystring(window.location);
    
      if(query.reserved == 1) {
        activeIndex = termsAndConditionsItems.findIndex(item => 
          item.Title.includes("Reserve"))
      }
    }    

    return (<Layout version="standard">
                <SEO {...seoData} />
                <Banner {...metadata} isBiggerBanner={false} />
                <GenericBannerContainer
                  padding={{
                    mobile: {
                        top: 60,
                        bottom: 80
                    },
                    desktop: {
                        top: 80,
                        bottom: 100
                    }
                  }}
                  backgroundColor={theme.brand.colors.beige}>
                    <FilterableHtmlContentBlock ContentItems={termsAndConditionsItems} activeIndex={activeIndex >= 0 ? activeIndex : 0}></FilterableHtmlContentBlock>
                </GenericBannerContainer>
          </Layout>
      );
  }
}

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemTermsAndConditions {
    elements {
      general_page_content__header_image {
        value {
          url
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      general_page_content__kiwivip_signup {
        value {
          codename
          name
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          codename
          name
        }
      }
      general_page_content__title {
        value
      }
      general_page_content__title_position {
        value {
          codename
          name
        }
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          codename
          name
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
      url {
        value
      }
      terms_and_conditions {
        linked_items {
          ... on KontentItemRichHtmlBlock {
            elements {
              content {
                value
              }
              title {
                value
              }
            }
          }
        }
      }
    }
  }
}


`

export default TermsAndConditionsLandingPage